<template>
    <div>
        <TitleBar>设置数据推送地址</TitleBar>
        <div class="main-container">
            <div class="box">
                <h1>将数据以JSON格式发送给第三方</h1>
                <h2>CMS内容管理平台在收到数据推送后，向对应的HTTP地址POST发送JSON格式数据。<br>如果推送失败，CMS会重试推送。</h2>
                <h3>授权域回调<span> *</span></h3>
                <div class="input"><Input v-model="dataPushUrls" placeholder="多个可用英文逗号分隔，例如：http://www.douyin.com,http://star.douyin.com" style="width: 500px;" /></div>
                <div class="button"><Button @click="setCallbackUrls" type="primary">保存设置</Button></div>
            </div>
        </div>
    </div>
</template>
<script>
import TitleBar from "@/components/TitleBar";
import { getCallbackUrls, setCallbackUrls } from "@/api/data-push";

export default {
    name: "OfficialAccountList",
    components: {
        TitleBar
    },
    created() {
        this.getCallbackUrls();
    },
    data() {
        return {
            dataPushUrls: ""
        }
    },
    methods: {
        getCallbackUrls() {
            getCallbackUrls().then(response => {
                this.dataPushUrls = response;
            });
        },
        setCallbackUrls() {
            setCallbackUrls(this.dataPushUrls).then(() => {
                this.$Message.success("设置成功！");
            });
        }
    }
}
</script>
<style lang="less" scoped>
    .box {
        width: 940px;
        margin: 0 auto;
        background-color: #ffffff;
        border-radius: 2px;
        overflow: hidden;
    }

    h1 {
        font-size: 16px;
        color: #0A2A4C;
        font-weight: 600;
        height: 55px;
        line-height: 55px;
        margin: 0;
        padding: 0 24px;
        border-bottom: 1px solid #E8EAEC;
    }

    h2 {
        font-size: 14px;
        color: #0A2A4C;
        font-weight: 400;
        line-height: 26px;
        margin: 24px 24px 0 24px;
    }

    h3 {
        font-size: 14px;
        color: #0A2A4C;
        font-weight: 600;
        line-height: 22px;
        height: 22px;
        margin: 32px 24px 0 24px;

        span {
            color: #ff0000;
        }
    }

    .input {
        margin: 8px 24px 0 24px;
    }

    .button {
        margin: 24px 24px 36px 24px;
    }
</style>