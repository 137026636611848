import request from '@/util/request';
import qs from "qs";

export function getCallbackUrls() {
    return request({
        url: '/data-push-setting/getCallbackUrls',
        method: 'get'
    });
};

export function setCallbackUrls(dataPushUrls) {
    return request({
        url: '/data-push-setting/setCallbackUrls',
        method: 'post',
        data: qs.stringify({dataPushUrls})
    });
}
